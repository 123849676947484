import { toRaw } from 'vue'
export default {
  namespaced: true,
  state: {
    total: 0,
    currentContact: null,
    contacts: [],
    options: [],
    responsibles: [],
    //sharedPermission: ""
  },
  getters: {
    currentContact: (state) => state.currentContact,
    contacts: (state) => state.contacts,
    options: (state) => state.options,
    total: (state) => state.total,
    responsibles: (state) => state.responsibles,
    //sharedPermission: (state) => state.sharedPermission
  },
  mutations: {
    SET_CURRENT_CONTACT (state, contact) {
      state.currentContact = contact
    },
    SET_CURRENT_CONTACT_ACTIVITY_CONCLUSION (
      state,
      { activityId, conclusionAt }
    ) {
      if (state.currentContact.activities) {
        const [created] = state.currentContact.activities?.created.filter(
          (activity) => activity.id === activityId
        )
        const [invited] = state.currentContact.activities?.invited.filter(
          (activity) => activity.id === activityId
        )

        if (created) created.conclusionAt = conclusionAt
        if (invited) invited.conclusionAt = conclusionAt
      }
    },
    SET_CURRENT_CONTACT_POSITIONS (state, positions) {
      state.currentContact = {
        ...state.currentContact,
        positions
      }
    },
    SET_CURRENT_CONTACT_ACTIVITIES (state, activities) {
      state.currentContact = {
        ...state.currentContact,
        activities
      }
    },
    SET_CONTACTS (state, contacts) {
      state.contacts = contacts
    },
    SET_OPTIONS (state, options) {
      state.options = options
    },
    SET_TOTAL (state, total) {
      state.total = total
    },
    SET_RESPONSIBLES (state, data) {
      state.responsibles = data
    },
    //SET_SHARED_PERMISSION (state, data) {
    //  state.sharedPermission = data
    //},
    UPDATE_CONTACT_STATUS (state, { index, status }) {
      state.contacts[index].status = status
    },
    UPDATE_CONTACT (state, { index, contact }) {
      state.contacts[index] = contact
    }
  },
  actions: {
    filterContacts ({ commit }, data) {
      commit('SET_CONTACTS', data)
    },
    loadContacts ({ commit, dispatch }, parameters) {
      const { $axios } = this.state

      const params = {}

      if (parameters?.page) params.page = parameters.page

      if (parameters?.query) params.query = parameters.query

      if (parameters?.limit) params.limit = parameters.limit

      //$axios
      //.get('http://localhost:3000/api/upload/getPermission').then(res => {
      //console.log("permission:", res.data)
      //commit('SET_SHARED_PERMISSION', res.data)
      //});


      return $axios
        .get('/contact', { params })
        .then(({ data: response }) => {
          if (parameters?.reload) {
            console.log("load by parameters?.reload");
            commit('SET_CONTACTS', [...response.result])
            commit('SET_TOTAL', response.total)
            return response.result.length
          }

          const records = toRaw(this.state.contacts.contacts)
          const identificators = records.map((record) => record.id)

          console.log("load by records");
          commit('SET_CONTACTS', [
            ...records,
            ...response.result.filter(
              (record) => !identificators.includes(record.id)
            ),
          ])

          commit('SET_TOTAL', response.total)

          return response.result.length
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao carregar contatos',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    getAllResponsible ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios
        .get('/contact/allResponsible')
        .then(({ data: response }) => {
          commit('SET_RESPONSIBLES', response)

          return response.length
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao carregar contatos',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadOptions ({ commit, dispatch }) {
      const { $axios } = this.state

      $axios
        .get('/contact/options')
        .then((response) => {
          commit('SET_OPTIONS', response.data.roles)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText || 'Falha ao carregar opções de contatos',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    registerContacts ({ commit, dispatch }, item) {
      const { $axios } = this.state
      const record = item
      return $axios
        .post('/contact', record)
        .then(({ data: response }) => {
          const records = toRaw(this.state.contacts.contacts)
          const roles = toRaw(this.state.contacts.options)
          record.id = response.id
          record.role = record.roleId
            ? roles.filter((option) => option.id === record.roleId)
            : roles.length > 0 ? roles[0] : null;

          commit('SET_CONTACTS', [record, ...records])
          return response
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha na inserção',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    updateContacts ({ dispatch, commit }, contact) {
      const { $axios } = this.state
      return $axios
        .put(`/contact/${contact.id}`, contact)
        .then((response) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Contato atualizado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )

          const contactIndex = this.state.contacts.contacts.findIndex(
            (contactObject) => contactObject.id === contact.id
          )

          if (contactIndex !== -1) {
            commit('UPDATE_CONTACT', { index: contactIndex, contact: contact })
          }

          return response.data
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText || 'Falha ao atualizar dados do contato',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )
        })
    },
    setActivityConclusion ({ commit }, { activityId, conclusionAt }) {
      commit('SET_CURRENT_CONTACT_ACTIVITY_CONCLUSION', {
        activityId,
        conclusionAt
      })
    },
    setCurrentContact ({ commit }, contact) {
      commit('SET_CURRENT_CONTACT', contact)
    },
    loadCurrentContactDetails ({ rootState, commit, dispatch }, contactId) {
      const { $axios } = rootState
      return $axios
        .get(`/contact/${contactId}`)
        .then(({ data: contact }) => {
          commit('SET_CURRENT_CONTACT', contact)
          return contact
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar posições do contato',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadCurrentContactPositions ({ state, rootState, commit, dispatch }) {
      const { currentContact } = state
      const { $axios } = rootState

      return $axios
        .get(`/contact/${currentContact.id}/positions`)
        .then(({ data: response }) => {
          commit('SET_CURRENT_CONTACT_POSITIONS', response)
          return response.length
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar posições do contato',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadCurrentContactActivities ({ state, rootState, commit, dispatch }) {
      const { currentContact } = state
      const { $axios } = rootState

      if (!currentContact.id) return

      return $axios
        .get(`/contact/${currentContact.id}/activities`)
        .then(({ data: response }) => {
          commit('SET_CURRENT_CONTACT_ACTIVITIES', response)
          return response.length
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar atividades do contato',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    blockContact ({ state, rootState, commit, dispatch }, id) {
      const { currentContact } = state
      const { $axios } = rootState
      return $axios
        .put(`/contact/${id || currentContact.id}/block`)
        .then(({ data: response }) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Bloqueado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )

          const contactIndex = this.state.contacts.contacts.findIndex(
            (contactObject) => contactObject.id === id || currentContact.id
          )

          if (contactIndex !== -1) {
            commit('UPDATE_CONTACT_STATUS', { index: contactIndex, status: 2 })
          }

          return response
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao bloquear contato',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    unlockContact ({ state, rootState, commit, dispatch }, id) {
      const { currentContact } = state
      const { $axios } = rootState
      return $axios
        .put(`/contact/${id || currentContact.id}/unlock`)
        .then(({ data: response }) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Desbloqueado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )

          const contactIndex = this.state.contacts.contacts.findIndex(
            (contactObject) => contactObject.id === id || currentContact.id
          )

          if (contactIndex !== -1) {
            commit('UPDATE_CONTACT_STATUS', { index: contactIndex, status: 1 })
          }

          return response
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao desbloquear contato',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    excludeContact ({ state, commit, rootState, dispatch }, id) {
      const { currentContact } = state
      const { $axios } = rootState
      return $axios
        .delete(`/contact/${id || currentContact.id}`)
        .then(({ data: response }) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Excluido com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          const contacts = this.state.contacts.contacts.filter(
            (contact) => contact.id !== id
          )
          commit('SET_CONTACTS', contacts)
          return response
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha ao excluir contato',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    async filterSearchText ({ dispatch }, data) {
      const { $axios } = this.state
      if (!data.text) {
        dispatch('loadContacts')
        return
      }

      return $axios
        .post(`/contact/search`, { search: data.text })
        .then((contacts) => {
          return contacts.data.result
        })
    }
  }
}
