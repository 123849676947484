<template>
  <div :class="`${show ? 'show' : ''} selected-candidate`">
    <div class="card">
      <div class="card-content h-100">
        <div
          class="tab d-flex-column justify-between h-100"
          :class="{ translate: tab === 'historico' }"
        >
          <div class="h-100">
            <div class="tab-header">
              <button
                v-for="elem in tabs"
                :key="elem.name"
                class="btn sm"
                :class="{ active: tab === elem.name }"
                @click="changeTab(elem.name)"
              >
                {{ elem.label }}
              </button>
              <b-tooltip>
                <template #activator="{ on }">
                  <div
                    class="btn icon sm"
                    :class="{ 'btn-coral': tab === 'anexos' }"
                    v-on="on"
                    @click="changeTab('anexos')"
                  >
                    <em class="fal fa-paperclip"></em>
                  </div>
                </template>
                Anexos
              </b-tooltip>
              <button
                class="close-candidate-btn btn icon xs btn-coral"
                @click="closeCandidateDrawer"
              >
                <em class="fal fa-arrow-right-to-line"></em>
              </button>
            </div>
            <div class="tab-content h-100" stage="Projetos">
              <div
                v-if="currentCandidate && currentCandidate.id"
                class="candidate-item scrollable"
              >
                <div class="d-flex w-100">
                  <div class="w-100 d-flex">
                    <b-avatar
                      size="md"
                      :name="currentCandidate.name"
                      class="candidate-picture"
                    >
                      <img
                        v-if="currentCandidate.photo"
                        :src="currentCandidate.photo + blobPermission"
                        :alt="currentCandidate.name"
                      />
                    </b-avatar>
                    <div class="candidate-data">
                      <p class="f-paragraph-md font-weight-600">
                        {{ currentCandidate.name }}
                      </p>
                      <p
                        v-if="currentCandidate.companies?.[0]?.role"
                        class="f-paragraph-xs text-color-light"
                      >
                        {{ currentCandidate.companies?.[0]?.role }}
                      </p>
                      <p
                        v-if="currentCandidate.companies?.[0]?.name"
                        class="f-paragraph-xs text-color-light"
                      >
                        {{ currentCandidate.companies?.[0]?.name }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-end">
                    <PercentageCircle
                      v-if="tab === 'dados'"
                      :percentage="currentCandidate.compatibility"
                      :small="true"
                    />
                  </div>
                </div>

                <Dados v-if="tab === 'dados'" :candidate="currentCandidate" />
                <Curriculo
                  v-if="tab === 'curriculo'"
                  :candidate="currentCandidate"
                  :candidancy="true"
                />
                <Anexos
                  v-if="tab === 'anexos'"
                  :candidate="currentCandidate"
                  :candidacy="currentCandidate"
                  :candidancy="true"
                />
                <Referencias
                  v-if="tab === 'referencias'"
                  :candidancy="true"
                  @openUser="openUserModal"
                  @novaReferencia="openNovaReferencia"
                  @novoContato="openNovoContato"
                />
                <Historico
                  v-if="tab === 'historico'"
                  :hide-info="['candidato', 'descricao']"
                />
              </div>
              <div class="tab-actions d-flex justify-between">
                <button class="btn btn-coral outlined sm" @click="remove">
                  <em class="fal fa-xmark"></em>
                  <span>Remover</span>
                </button>
                <button
                  :disabled="validateWinnerRollback"
                  :class="!winner ? 'btn btn-success sm' : 'btn btn-coral sm'"
                  @click="!winner ? winnerCandidate() : winnerRollback()"
                >
                  <em class="fal fa-trophy"></em>
                  <span>Placed</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dados from '../tab/Dados.vue'
import Curriculo from '../candidato/curriculo.vue'
import Referencias from '../candidato/referencias.vue'
import Anexos from '../contato/anexos.vue'
import Historico from '../candidato/historico.vue'
import PercentageCircle from '../PercentageCircle.vue'

import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'CandidatesDrawer',
  components: {
    Dados,
    Curriculo,
    Anexos,
    Referencias,
    PercentageCircle,
    Historico
  },
  emits: ['openUser', 'novaReferencia', 'closeCandidateList', 'novoContato'],
  data() {
    return {
      candidateDrawer: false,
      tab: 'dados',
      tabs: [
        {
          name: 'dados',
          label: 'Dados'
        },
        {
          name: 'curriculo',
          label: 'Curriculo'
        },
        // {
        //   name: 'atividade',
        //   label: 'Atividades'
        // },
        {
          name: 'referencias',
          label: 'Referências'
        },
        {
          name: 'historico',
          label: 'Histórico'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      blobPermission: 'session/blobPermission',
      currentCandidate: 'jobs/currentCandidate',
      currentPipeline: 'jobs/currentPipeline',
      candidates: 'jobs/candidates'
    }),
    show() {
      return !!this.currentCandidate.id
    },
    winner() {
      if (!this.candidates.length) return
      return this.candidates.find((candidate) => !!candidate.winner)
    },
    validateWinnerRollback() {
      if (!this.candidates.length) return
      if (!this.winner) return
      if (this.currentCandidate.contactId !== this.winner.contactId) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      setCurrentCandidate: 'jobs/setCurrentCandidate',
      setCurrentHistory: 'history/setCurrent',
      loadContactData: 'curriculum/loadContactData',
      setCurrentTab: 'jobs/setCurrentTab',
      candidateWinner: 'jobs/candidateWinner',
      candidateWinnerRoolback: 'jobs/candidateWinnerRoolback',
      removeCandidate: 'jobs/removeCandidate',
      changeActivePhase: 'home/changeActivePhase',
      getOnlyPlaceds: 'home/getOnlyPlaceds',
      getOnlyPlacedsCount: 'home/getOnlyPlacedsCount',
      //getOnlySteps: 'home/getOnlySteps',
      getOnlyBusiness: 'home/getOnlyBusiness',
      getOnlyLeads: 'home/getOnlyLeads',
      getOnlyProspects: 'home/getOnlyProspects',
      getOnlyProjects: 'home/getOnlyProjects',
      getOnlyProspectsCount: 'home/getOnlyProspectsCount',
      getOnlyLeadsCount: 'home/getOnlyLeadsCount',
      getOnlyBusinessCount: 'home/getOnlyBusinessCount',
      getOnlyProjectsCount: 'home/getOnlyProjectsCount',
      savePosition: 'home/savePosition',
      loadReference: 'jobs/loadReference',
      loadCandidates: 'jobs/loadCandidates',
      updateContacts: 'contacts/updateContacts'
    }),
    async closeCandidateDrawer() {
      this.setCurrentCandidate({})
    },
    async changeTab(tab) {
      this.tab = tab
      this.setCurrentTab(tab)
      if (this.tab === 'dados') {
        console.log(this.currentCandidate)
      }
      if (this.tab === 'historico') {
        this.setCurrentHistory({
          positionId: this.currentPipeline.id,
          contactId: this.currentCandidate.contactId
        })
      }

      if (this.tab === 'curriculo') {
        this.loadContactData(this.currentCandidate.contactId)
      }

      if (this.tab === 'referencias') {
        this.loadReference(this.currentCandidate.contactId)
      }
    },
    async winnerCandidate() {
      await this.candidateWinner({
        pipelineId: this.currentPipeline.id,
        contactId: this.currentCandidate.contactId,
        status: 15,
        statusRollback: this.currentCandidate.status,
        callback: async () => {
          await this.updateContacts({
            id: this.currentCandidate.contactId,
            name: this.currentCandidate.name,
            actualJob: this.currentPipeline.name,
            actualCompanyId: this.currentPipeline.companyId,
            emails: this.currentCandidate.emails,
            phones: this.currentCandidate.phones
          })
          await this.closeCandidateDrawer()
          await this.changePhase()
        }
      })
    },
    async changePhase() {
      const position = Object.assign({}, this.currentPipeline)

      const buttonOptions = {
        Placed: 5
      }

      this.$emit('closeCandidateList')

      position.stepId = 5
      position.placedStep = 5
      position.placedStepDateCreated = moment().format('YYYY-MM-DD h:mm:ss')
      await this.savePosition(position)

      await Promise.all([
        this.getOnlyPlaceds(),
        this.getOnlyPlacedsCount(),
        this.getOnlyProjects(),
        this.getOnlyProjectsCount(),
        this.getOnlyProspects(),
        this.getOnlyProspectsCount(),
        this.getOnlyLeads(),
        this.getOnlyBusiness(),
        this.getOnlyBusinessCount(),
        this.getOnlyLeadsCount()
      ])

      await this.changeActivePhase(buttonOptions['Placed'])
    },
    async changePhaseRollback() {
      const position = Object.assign({}, this.currentPipeline)
      const buttonOptions = {
        Projeto: 4
      }

      this.$emit('closeCandidateList')

      position.name = this.currentPipeline.name
      position.stepId = 4
      position.placedStep = null
      position.placedStepDateCreated = null

      position.projectStatusId = 4
      position.projectStep = 4
      position.projectStepDateCreated = moment().format('YYYY-MM-DD h:mm:ss')
      await this.savePosition(position)

      await Promise.all([
        this.getOnlyPlaceds(),
        this.getOnlyPlacedsCount(),
        this.getOnlyProjects(),
        this.getOnlyProjectsCount(),
        this.getOnlyProspects(),
        this.getOnlyProspectsCount(),
        this.getOnlyLeads(),
        this.getOnlyBusiness(),
        this.getOnlyBusinessCount(),
        this.getOnlyLeadsCount()
      ])

      await this.changeActivePhase(buttonOptions['Projeto'])
    },
    async winnerRollback() {
      await this.candidateWinnerRoolback({
        pipelineId: this.currentPipeline.id,
        contactId: this.currentCandidate.contactId,
        status: this.currentCandidate.statusRollback,
        callback: async () => {
          await this.closeCandidateDrawer(), await this.changePhaseRollback()
        }
      })
    },
    remove() {
      this.removeCandidate({
        id: this.currentCandidate.id,
        contactId: this.currentCandidate.contactId
      })
    },
    openNovaReferencia() {
      this.$emit('novaReferencia')
    },
    openNovoContato() {
      this.$emit('novoContato')
    },
    openUserModal(data) {
      this.$emit('openUser', {
        id: data.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        photo: data.photo
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-candidate {
  width: 390px;
  height: 100%;
  max-width: 0;
  transform: translateX(390px);
  transition: all 0.25s;

  &.show {
    max-width: 390px;
    transform: translateX(0);
  }

  .tab-header {
    background-color: var(--bg-highlight);
    padding: 8px 24px 0px;
    position: relative;

    .close-candidate-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .tab.translate .tab-header > .btn:not(.close-candidate-btn) {
    transform: translateX(-100px);
  }

  .tab-content {
    justify-content: space-between;
    padding-bottom: 48px;
  }

  .tab-actions {
    padding: 1rem;
    border: 1px solid var(--border);
  }

  .card {
    width: 390px;
    height: 100%;
    max-height: 100%;
    border-top: none;
  }

  .card-content,
  .tab {
    height: 100%;
  }

  .h-100 {
    height: 100%;
  }
}

.candidate-item {
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  &:first-of-type {
    border-top: 1px solid var(--border);
  }

  &.scrollable > div:first-child {
    border-bottom: 1px solid var(--border);
    padding-bottom: 16px;
  }
}

.candidate-data {
  display: flex;
  flex-direction: column;
  gap: 6px;

  p {
    margin: 0;
  }
}

.candidate-picture {
  margin-right: 16px;
}
</style>
