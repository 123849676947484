const getItem = (key) => sessionStorage.getItem(key)
const setItem = (key, value) =>
  sessionStorage.setItem(
    key,
    value instanceof Object ? JSON.stringify(value) : value
  )

export default {
  namespaced: true,
  state: {
    currentStep: null,
    blobPermission: null,
  },
  getters: {
    currentStep: (state) => {
      const step = parseInt(state.currentStep || getItem('session.currentStep'))

      if (!step || isNaN(step) || step < 0 || step > 5) return 1

      return step
    },
    blobPermission: (state) => state.blobPermission,
  },
  mutations: {
    SET_CURRENT_STEP (state) {
      state.currentStep = getItem('session.currentStep') || 1
    },
    SET_BLOB_PERMISSION (state, permission) {
      state.blobPermission = permission;
    },
  },
  actions: {
    setCurrentStep ({ commit }, step) {
      setItem('session.currentStep', step)
      commit('SET_CURRENT_STEP')
    },
    fetchBlobPermission ({ commit }) {
      const { $axios } = this.state;
      $axios.get(`${process.env.VUE_APP_API_URL}/upload/getPermission` || 'http://localhost:3000/api/upload/getPermission').then(res => {
        commit('SET_BLOB_PERMISSION', res.data);
      });
    }
  }
}
