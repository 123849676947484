import { openWindow } from '@/helpers'

export default {
  namespaced: true,
  state: {
    current: {
      positionId: null,
      contactId: null
    },
    records: [],
    recordsPosition: [],
    typeHistory: []
  },
  getters: {
    currentHistoryIdentifier: (state) => state.current,
    records: (state) => state.records,
    recordsPosition: (state) => state.recordsPosition,
    typeHistory: (state) => state.typeHistory
  },
  mutations: {
    APPEND_RECORDS (state, data) {
      state.records = data
    },
    APPEND_RECORDS_POSITION (state, data) {
      state.recordsPosition = data
    },
    SET_CURRENT (state, { positionId, contactId, component }) {
      state.current = {
        positionId: positionId,
        contactId: contactId || null,
        component: component || null
      }
    },
    SET_RECORDS_REMOVE (state, data) {
      state.records = data
    },
    SET_TYPE_HISTORY (state, data) {
      state.typeHistory = data
    }
  },
  actions: {
    setCurrent ({ commit }, { positionId, contactId, component }) {
      commit('SET_CURRENT', { positionId, contactId, component })
    },
    loadHistory ({ commit, dispatch, state }) {
      const { $axios } = this.state

      const route = `contact/${state.current.contactId}/history`

      return $axios
        .get(route)
        .then(({ data }) => {
          commit('APPEND_RECORDS', data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar histórico do candidato',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadHistoryPosition ({ commit, dispatch, state }) {
      const { $axios } = this.state
      const route = `contact/history/${state.current.positionId}`

      return $axios
        .get(route)
        .then(({ data }) => {
          commit('APPEND_RECORDS_POSITION', data)
        })
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar histórico da posição',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    createHistory (
      { dispatch, rootState, state },
      { title, description, typeHistoryId, isDraft, component }
    ) {
      const { $axios } = rootState

      return $axios
        .post(`/contact/history/${isDraft ? 'draft' : ''}`, {
          positionId: state.current.positionId,
          contactId: state.current.contactId,
          title,
          description,
          typeHistoryId
        })
        .then(({ data }) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Histórico criado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          const { webLink } = data
          if (webLink) {
            openWindow(webLink)
          } else {
            dispatch('loadHistory')
            dispatch('loadHistoryPosition')
          }
        })
        .catch((error) => {
          if (!error.response?.data?.authUrl) {
            console.error(error)

            const message =
              error.response?.data?.message || 'Falha ao criar mensagem'

            dispatch(
              'snackbar/showSnackbar',
              {
                message,
                type: 'error'
              },
              { root: true }
            )

            return
          }

          const { authUrl } = error.response.data

          openWindow(authUrl)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'É necessário autenticar-se para criar uma mensagem',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    syncHistory ({ dispatch, rootState }) {
      const { $axios } = rootState

      return $axios.post('/contact/history/sync').catch((error) => {
        if (!error.response?.data?.authUrl) {
          console.error(error)

          const message =
            error.response?.data?.message || 'Falha ao sincronizar atividades'

          dispatch(
            'snackbar/showSnackbar',
            {
              message,
              type: 'error'
            },
            { root: true }
          )

          return
        }

        const { authUrl } = error.response.data

        openWindow(authUrl)

        dispatch(
          'snackbar/showSnackbar',
          {
            message: 'É necessário autenticar-se para sincronizar as mensagens',
            secondsToClose: 5,
            type: 'warn'
          },
          { root: true }
        )

        return Promise.reject(error)
      })
    },
    removeHistory ({ dispatch, commit, rootState, state }, id) {
      const { $axios } = rootState

      return $axios.delete(`/contact/history/${id}`)
        .then((result) => {
          const historiesCandidate = this.state.history.records.filter(
            (history) => history.id !== id
          )

          const historiesPosition = this.state.history.recordsPosition.filter(
            (history) => history.id !== id
          )

          commit('APPEND_RECORDS', historiesCandidate)
          commit('APPEND_RECORDS_POSITION', historiesPosition)
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Histórico removido com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result.data
        }).catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao carregar histórico da posição',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    updateHistory ({ dispatch, commit, rootState }, data) {
      const { $axios } = rootState

      return $axios.put(`/contact/history/${data.id}`, data)
        .then((result) => {
          dispatch('loadHistory')
          dispatch('loadHistoryPosition')
          dispatch(
            'snackbar/showSnackbar',
            {
              message: 'Histórico atualizado com sucesso',
              secondsToClose: 5,
              type: 'success'
            },
            { root: true }
          )
          return result.data
        }).catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao atualizar histórico',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    loadTypeHistory ({ dispatch, commit, rootState }) {
      const { $axios } = rootState

      return $axios.get(`/contact/history/type/options`)
        .then((result) => {
          commit('SET_TYPE_HISTORY', result.data)
          return result.data
        }).catch((error) => {
          dispatch(
            'snackbar/showSnackbar',
            {
              message:
                error.parsedErrorText ||
                'Falha ao listar tipo de histórico',
              secondsToClose: 5,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    }
  }
}
