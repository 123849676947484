<template>
  <div class="dialog" :class="{ active: dialog }">
    <div class="dialog-content md">
      <div class="dialog-content-header">
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-user"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2">Contatos</div>
        <button class="btn text icon" @click="close()">
          <em class="fal fa-times"></em>
        </button>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-body">
        <div class="pa-1 d-flex gap-8">
          <b-textfield v-model="filter" placeholder="Buscar" name="search" type="search" append-icon="fal fa-search"
            class="sm mb-0 flex-grow-1" />
          <button class="btn btn-coral sm" @click="openModal('new')">
            <em class="fal fa-user-plus"></em>
            <span>Novo</span>
          </button>
        </div>
        <span class="divider"></span>
        <div class="list list-with-divider bg-contrast">
          <div v-for="contact in recordList" :key="contact.id" class="list-item">
            <b-radio v-if="onlyone" :id="contact.id" v-model="selectedContacts" :input-value="contact.id"
              class="invert pb-2 pt-2">
              <template #label>
                <b-avatar class="mr-2" :name="contact.name" size="xs">
                  <img :src="contact.photo ? contact.photo + blobPermission : null" :alt="contact.name" />
                </b-avatar>
                <div class="flex-grow-1">
                  <div class="paragraph sm">
                    <strong v-text="contact.name"></strong>
                  </div>
                  <div class="paragraph xs text-color-light" v-text="contact.cpf"></div>
                </div>
              </template>
            </b-radio>
            <b-checkbox v-else :id="'contact_' + contact.id" v-model="selectedContacts" :input-value="contact.id"
              class="invert pb-2 pt-2">
              <template #label>
                <b-avatar class="mr-2" :name="contact.name" size="xs">
                  <img :src="contact.photo ? contact.photo + blobPermission : null" :alt="contact.name" />
                </b-avatar>
                <div class="flex-grow-1">
                  <div class="paragraph sm">
                    <strong v-text="contact.name"></strong>
                  </div>
                  <div class="paragraph xs text-color-light" v-text="contact.cpf"></div>
                </div>
              </template>
            </b-checkbox>
          </div>
          <div v-if="records?.length >= quantity" class="mt-1 mb-1 text-center">
            <b-button class="btn cursor-pointer" :loading="loadingMore" @click="loadMoreContacts">
              Carregar mais
            </b-button>
          </div>
        </div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-footer">
        <span class="paragraph text-color-light">{{ recordList.length }}/{{ total }}</span>
        <span class="flex-grow-1" />
        <button class="btn outlined sm" @click="clear">Limpar</button>
        <button class="btn btn-coral sm" @click="saveAndClose">Aplicar</button>
      </div>
    </div>
    <NovoContato ref="new" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import mixins from '@/mixins'
import NovoContato from '@/components/modals/novo-contato.vue'

export default {
  name: 'ContatosModal',
  components: {
    NovoContato
  },
  mixins: [mixins],
  props: {
    onlyone: Boolean
  },
  data () {
    return {
      filter: '',
      filtered: [],
      loadingMore: false,
      dialog: false,
      type: 'contact',
      quantity: 10,
      screen: ''
    }
  },
  computed: {
    ...mapGetters({
      blobPermission: 'session/blobPermission',
      newCompany: 'company/newCompany',
      records: 'contacts/contacts',
      total: 'contacts/total'
    }),
    selectedContacts: {
      get () {
        return this.$store.getters['company/selectedContacts']
      },
      set (value) {
        this.setSelectedContacts(value)
      }
    },
    filteredList () {
      if (!this.filter?.length)
        return this.records.filter(
          (record) => record.status === 1 || !record.status
        )
      return this.records.filter(
        (record) =>
          record.name
            .toLowerCase()
            .includes(this.filter.trim().toLowerCase()) &&
          (record.status === 1 || !record.status)
      )
    },
    recordList () {
      return this.filteredList.slice(0, this.quantity)
    }
  },
  watch: {
    dialog: function (value) {
      if (!value) return
      this.quantity = 10
    },
    filter: function () {
      this.quantity = 10
    }
  },
  methods: {
    ...mapActions({
      getComercialContacts: 'contacts/loadContacts',
      addContact: 'company/addContactsToCompany',
      setSelectedContacts: 'company/setSelectedContacts',
      showSnackbar: 'snackbar/showSnackbar',
      setCurrentContact: 'contacts/setCurrentContact'
    }),
    ...mapMutations({
      clearContacts: 'company/CLEAR_CONTACTS',
      clearCompany: 'company/CLEAR_COMPANY'
    }),
    loadMoreContacts () {
      if (this.filteredList.length > this.quantity) {
        this.quantity += 10
        return
      }

      this.quantity += 10

      const params = {
        page: this.quantity / 10
      }

      if (this.filter?.toLowerCase().trim().length)
        params.query = this.filter?.toLowerCase().trim()

      this.loadingMore = true

      this.getComercialContacts(params)
        .then((results) => {
          this.loadingMore = false
          if (results > 0) return
          this.quantity -= 10
        })
        .catch(() => {
          this.loadingMore = false
        })
    },
    saveAndClose () {
      const filteredList = this.newCompany[
        this.type === 'comercial' ? 'comercialContacts' : 'councilContacts'
      ].filter((contact) => this.selectedContacts.includes(contact.id))

      const filteredIds = filteredList.map((contact) => contact.id)

      const notIncluded = this.selectedContacts.filter(
        (id) => !filteredIds.includes(id)
      )

      if (notIncluded.length) {
        filteredList.push(
          ...this.records.filter((contact) => notIncluded.includes(contact.id))
        )
      }

      if (this.screen === 'users' && filteredList.length > 1) {
        this.showSnackbar({
          message: 'Selecione somente 1 contato',
          secondsToClose: 10,
          type: 'error'
        })
        return
      } else if (this.screen === 'users' && filteredList.length === 1) {
        this.setCurrentContact(filteredList[0])
        this.close()
        return
      }

      this.addContact({
        [this.type === 'comercial' ? 'contatos' : 'council']: filteredList,
        type: this.type === 'comercial' ? 'contact' : 'council'
      })

      this.close()
    },
    open (type, screen) {
      this.type = type
      this.dialog = true
      if (screen === 'users') {
        this.screen = 'users'
      }
    },
    close () {
      this.dialog = false
      this.$emit('closeContact')
    },
    clear () {
      this.clearCompany(
        this.type === 'comercial' ? 'comercialContacts' : 'councilContacts'
      )
      this.setSelectedContacts([])
      this.selectedContacts = []
      this.clearContacts()
    }
  }
}
</script>
