<template>
  <div class="dialog" :class="{ active: dialog }">
    <div class="dialog-content xl no-line">
      <div class="dialog-content-header bg-default">
        <button class="btn icon btn-coral" @click="openNew">
          <em class="fal fa-user-plus"></em>
        </button>
        <div class="flex-grow-1 text-uppercase font-weight-600">
          Novo Candidato
        </div>
        <div>
          <b-textfield v-model="filter" placeholder="Buscar" name="search" type="search" append-icon="fal fa-search"
            class="sm mb-0 mr-1" @keyup="searchContact" />
        </div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-body">
        <div class="bg-contrast">
          <ul>
            <li v-for="candidate in candidatesList" :key="candidate.id" class="candidate-item">
              <b-avatar class="candidate-picture" size="md" :name="candidate.name">
                <img v-if="candidate.photo" :src="candidate.photo + blobPermission" :alt="candidate.name" />
              </b-avatar>
              <div class="candidate-data">
                <div class="candidate-pii">
                  <h4>{{ candidate.name }}</h4>
                  <small v-if="candidate.companies && candidate.companies.length > 0" class="f-paragraph-xxs">
                    {{ candidate.companies[0].name }}
                  </small>
                  <br v-if="candidate.companies && candidate.companies.length > 0" />
                  <small v-if="candidate.companies && candidate.companies.length > 0" class="f-paragraph-xxs">
                    {{ candidate.companies[0].role }}
                  </small>
                </div>
              </div>
              <b-checkbox :id="candidate.id" v-model="selectedContacts" :input-value="candidate.id"
                class="invert pb-2 pt-2">
                <template #label>
                  <div class="flex-grow-1"></div>
                </template>
              </b-checkbox>
            </li>
          </ul>
          <div class="dialog-content-footer justify-center">
            <span class="paragraph text-color-light">{{ recordList.length }}/{{ total }}</span>
            <div v-if="recordList?.length >= quantity" class="mt-1 mb-1 text-center">
              <b-button class="btn cursor-pointer" :loading="loadingMore" @click="loadMoreContacts">
                Carregar mais
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-footer justify-end">
        <button class="btn outlined sm" @click="close()">Cancelar</button>
        <button class="btn btn-coral sm" @click="save">Aplicar</button>
      </div>
    </div>
    <NovoContato ref="new" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import NovoContato from '@/components/modals/novo-contato.vue'
import { debounce } from '../../helpers'

export default {
  name: 'NovoCandidatoModal',
  components: {
    NovoContato
  },
  data () {
    return {
      filter: '',
      dialog: false,
      loadingMore: false,
      quantity: 10
    }
  },
  computed: {
    ...mapGetters({
      blobPermission: 'session/blobPermission',
      candidatesList: 'contacts/contacts',
      currentPipeline: 'jobs/currentPipeline',
      total: 'contacts/total'
    }),
    selectedContacts: {
      get () {
        return this.$store.getters['jobs/selectedContacts']
      },
      set (value) {
        this.setSelectedContacts(value)
      }
    },
    recordList () {
      return this.candidatesList
    }
  },
  watch: {
    dialog (value) {
      if (!!value && !this.candidatesList.length) {
        this.loadContacts()
      }
    }
  },
  methods: {
    ...mapActions({
      loadContacts: 'contacts/loadContacts',
      setSelectedContacts: 'jobs/setSelectedContacts',
      applicationCandidate: 'jobs/applicationCandidate',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    ...mapMutations({
      clearContacts: 'jobs/CLEAR_CONTACTS'
    }),
    openNovoCandidato () {
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.clearContacts()
    },
    async save () {
      if (this.selectedContacts.length === 0)
        return this.showSnackbar({
          message: 'Selecione ao menos um contato',
          secondsToClose: 5,
          type: 'warn'
        })
      await this.applicationCandidate({ pipelineId: this.currentPipeline.id })
      this.dialog = false
      this.clearContacts()
    },
    openNew () {
      this.$refs['new'].open()
    },
    searchContact () {
      if (this.filter.length < 3) {
        return this.loadContacts({ reload: true })
      }
      debounce(this.loadContacts({ query: this.filter, reload: true }), 500)
    },
    loadMoreContacts () {
      if (this.recordList.length > this.quantity) {
        this.quantity += 10
        return
      }

      this.quantity += 10

      const params = {
        page: this.quantity / 10
      }

      if (this.filter?.toLowerCase().trim().length) {
        params.query = this.filter?.toLowerCase().trim()
      }

      this.loadingMore = true

      this.loadContacts(params)
        .then((results) => {
          this.loadingMore = false
          if (results > 0) return
          this.quantity -= 10
        })
        .catch(() => {
          this.loadingMore = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  margin-right: 24px;
}

.candidate-item {
  border: '1px solid red';
  height: 84px;
  padding: 16px;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;

  &:first-of-type {
    border-top: 1px solid var(--border);
  }

  .candidate-picture {
    border-radius: 100%;
    margin-right: 16px;
  }

  .candidate-data {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
