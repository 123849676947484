<template>
  <div class="form-group" :class="{ 'has-error': errorText }">
    <label :key="id">{{ label }}</label>
    <div class="input-group">
      <div class="input-field has-icon">
        <select
          v-model="model"
          :id="id"
          :class="{ disabled }"
          :disabled="disabled"
          class="form-control"
          required
          @change="handleChange"
        >
          <option value="" disabled>{{ placeholder }}</option>
          <template v-if="options && options.length">
            <option
              v-for="item in options"
              :key="item[optionValue] || item"
              :value="item[optionValue] || item"
            >
              {{ item[optionText] || item }}
            </option>
          </template>
        </select>

        <!-- Ícone padrão -->
        <div class="icon">
          <em class="fal" :class="icon ? `fa-${icon}` : ''"></em>
        </div>

        <!-- Ícone de limpar (só aparece se houver um valor selecionado) -->
        <button v-if="model" class="clear-btn" @click="clearSelection">
          <em class="fal fa-times-circle"></em>
        </button>
      </div>
      <slot name="append-outer" />
    </div>
  </div>
</template>

<script>
import mixins from './mixins/validation'
export default {
  name: 'SelectField',
  mixins: [mixins],
  props: {
    label: String,
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: Array,
    placeholder: {
      type: String,
      default: 'Selecione...'
    },
    id: String,
    disabled: {
      default: false
    },
    optionText: {
      type: String,
      default: ''
    },
    optionValue: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: 'chevron-down'
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  data() {
    return {
      error: [],
      uud: Math.random().toString(16).slice(2)
    }
  },
  watch: {
    model() {
      this.validate()
    }
  },
  methods: {
    handleChange() {
      this.$emit('change', this.model)
    },
    clearSelection() {
      this.model = ''
      this.handleChange()
    }
  }
}
</script>

<style scoped>
.input-field {
  position: relative;
  display: flex;
  align-items: center;
}

.clear-btn {
  position: absolute;
  right: 35px;
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 16px;
}

.clear-btn:hover {
  color: #333;
}
</style>
