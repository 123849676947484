<template>
  <div class="dialog" :class="{ active: dialog }">
    <div class="dialog-content md">
      <div class="dialog-content-header">
        <div class="btn icon btn-coral no-hover">
          <em class="fal fa-user"></em>
        </div>
        <div class="flex-grow-1 h4 ml-2 mr-2">Conselho</div>
        <button class="btn text icon" @click="close()">
          <em class="fal fa-times"></em>
        </button>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-body">
        <div class="pa-1 d-flex gap-8">
          <b-textfield placeholder="Buscar" name="search" type="search" append-icon="fal fa-search"
            class="sm mb-0 flex-grow-1" />
          <button class="btn btn-coral sm" @click="openModal('new')">
            <em class="fal fa-user-plus"></em>
            <span>Novo</span>
          </button>
        </div>
        <span class="divider"></span>
        <div class="list list-with-divider bg-contrast">
          <div v-for="contact in councilContacts" :key="contact.id" class="list-item">
            <b-checkbox :id="1000 + contact.id" v-model="council" :input-value="contact" class="invert pb-2 pt-2">
              <template #label>
                <b-avatar :name="contact.name" class="mr-2" size="sm">
                  <img :src="contact.photo ? contact.photo + blobPermission : null" :alt="contact.name" />
                </b-avatar>
                <div class="flex-grow-1">
                  <div class="paragraph sm">
                    <strong>{{ contact.name }}</strong>
                  </div>
                  <div class="paragraph xs text-color-light">
                    {{ contact.cpf }}
                  </div>
                  <div class="paragraph xs text-color-light">
                    {{ contact.id }}
                  </div>
                </div>
              </template>
            </b-checkbox>
          </div>
        </div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-footer">
        <span class="paragraph text-color-light">{{ council.length }}/10</span>
        <span class="flex-grow-1" />
        <button class="btn outlined sm" @click="clear()">Limpar</button>
        <button class="btn btn-coral sm" @click="addContact({ council, type }), close()">
          Aplicar
        </button>
      </div>
    </div>
    <NovoContato ref="new" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import mixins from '@/mixins'
import NovoContato from '@/components/modals/novo-contato.vue'

export default {
  name: 'ConselhoModal',
  components: {
    NovoContato
  },
  mixins: [mixins],
  data () {
    return {
      dialog: false,
      council: [],
      type: 'council'
    }
  },
  computed: {
    ...mapGetters({
      blobPermission: 'session/blobPermission',
      councilContacts: 'contacts/contacts'
    })
  },
  watch: {
    dialog (value) {
      if (!!value && !this.councilContacts.length) {
        this.getContacts()
      }
    }
  },
  methods: {
    ...mapActions({
      getContacts: 'contacts/loadContacts',
      addContact: 'company/addContactsToCompany'
    }),
    ...mapMutations({ clearContacts: 'company/CLEAR_CONTACTS' }),
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.$emit('closeCouncil')
    },
    clear () {
      this.clearContacts('councilContacts')
      this.council = []
    }
  }
}
</script>
