<template>
  <div class="dialog" :class="{ active: dialog }">
    <div class="dialog-content xl no-line">
      <div class="dialog-content-header bg-default">
        <button class="btn icon btn-coral">
          <em class="fal fa-user-plus"></em>
        </button>
        <div class="flex-grow-1 text-uppercase font-weight-600">
          Nova Referência
        </div>
        <div>
          <b-textfield v-model="filter" placeholder="Buscar" name="search" type="search" append-icon="fal fa-search"
            class="sm mb-0 mr-1" />
        </div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-body">
        <div class="bg-contrast">
          <ul>
            <li v-for="candidate in recordList" :key="candidate.id" class="candidate-item">
              <b-avatar class="candidate-picture" size="md" :name="candidate.name">
                <img v-if="candidate.photo" :src="candidate.photo ? candidate.photo + blobPermission : null"
                  :alt="candidate.name" />
              </b-avatar>
              <div class="candidate-data">
                <div class="candidate-pii">
                  <h4>{{ candidate.name }}</h4>
                  <small class="f-paragraph-xxs">{{
                    candidate?.companies[0]?.name
                    }}</small>
                  <br v-if="candidate?.companies[0]?.name" />
                  <small class="f-paragraph-xxs">{{
                    candidate?.companies[0]?.role
                    }}</small>
                </div>
              </div>
              <b-checkbox :id="candidate.id" v-model="selectedContacts" :input-value="candidate.id"
                class="invert pb-2 pt-2">
                <template #label>
                  <div class="flex-grow-1"></div>
                </template>
              </b-checkbox>
            </li>
          </ul>
        </div>
      </div>
      <span class="divider"></span>
      <div class="dialog-content-footer justify-end">
        <button class="btn outlined sm" @click="close()">Cancelar</button>
        <button class="btn btn-coral sm" @click="save">Aplicar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'NovaReferenciaModal',
  data () {
    return {
      filter: '',
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      blobPermission: 'session/blobPermission',
      candidatesList: 'jobs/candidatesList',
      currentPipeline: 'jobs/currentPipeline'
    }),
    selectedContacts: {
      get () {
        return this.$store.getters['jobs/selectedContacts']
      },
      set (value) {
        this.setSelectedContacts(value)
      }
    },
    recordList () {
      return this.candidatesList.filter(
        (item) => !!item.name.toLowerCase().includes(this.filter.toLowerCase())
      )
    }
  },
  watch: {
    dialog (value) {
      if (!!value && !this.candidatesList.length) {
        this.listCandidates()
      }
    }
  },
  mounted () {
    this.listCandidates()
  },
  methods: {
    ...mapActions({
      listCandidates: 'jobs/listCandidates',
      setSelectedContacts: 'jobs/setSelectedContacts',
      applicationCandidate: 'jobs/applicationCandidate',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    ...mapMutations({
      clearContacts: 'jobs/CLEAR_CONTACTS'
    }),
    openNovaReferencia () {
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.clearContacts()
    },
    async save () { }
  }
}
</script>

<style scoped lang="scss">
.btn {
  margin-right: 24px;
}

.candidate-item {
  border: '1px solid red';
  height: 84px;
  padding: 16px;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;

  &:first-of-type {
    border-top: 1px solid var(--border);
  }

  .candidate-picture {
    border-radius: 100%;
    margin-right: 16px;
  }

  .candidate-data {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
