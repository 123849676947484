<template>
  <div v-if="contact" class="d-flex pa-3 gap-16 user-info">
    <div class="d-flex-column justify-between">
      <button class="btn sm icon">
        <em class="fal fa-lock"></em>
      </button>
      <button class="btn sm icon" disabled>
        <em class="fal fa-trash"></em>
      </button>
    </div>
    <b-avatar :name="contact.name" class="fs-200 square plonge-lg" :class="{ plonge: contact.role?.name === 'Plongê' }">
      <img :src="contact.photo ? contact.photo + blobPermission : null" :alt="contact.name" />
    </b-avatar>
    <div class="d-flex-column gap-8">
      <div class="h4">{{ contact.name }}</div>
      <div class="d-flex">
        <div class="label-status bg-coral d-flex gap-8">
          <span>{{ contact.role?.name }}</span>
          <span>•</span>
          <span></span>
          <em class="fal fa-square-arrow-up-right"></em>
        </div>
      </div>
      <div v-if="contact.emails?.length" class="paragraph xs text-color-light">
        {{ contact.emails[0].email }}
        <!-- Contato pode ter N emails -->
      </div>
      <div v-if="contact.linkedin" class="d-flex gap-8 text-color-light">
        <em class="fab fa-linkedin-in"></em>
        <div class="paragraph xs text-color-light">{{ contact.linkedin }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      contact: 'contacts/currentContact',
      blobPermission: 'session/blobPermission'
    })
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  border-bottom: 1px solid var(--border);
  background: var(--bg-contrast);
}
</style>
