<template>
  <ul>
    <li>
      <a href="#" role="button">
        <b-avatar class="with-border icon" :name="user?.name" size="xxs">
          <img v-if="user.photo" :src="user.photo + blobPermission" :alt="user?.name" />
        </b-avatar>
        <span>Perfil</span>
      </a>
    </li>
    <li>
      <b-tooltip x="left">
        <template #activator="{ on }">
          <a role="button" data-count="2" @click="$emit('openNotifications')" v-on="on">
            <div class="icon">
              <em class="fal fa-bell"></em>
            </div>
            <span>Notificações</span>
          </a>
        </template>
        Notificações
      </b-tooltip>
    </li>
    <li>
      <b-tooltip x="left">
        <template #activator="{ on }">
          <router-link to="/equipe" v-on="on">
            <div class="icon">
              <em class="fal fa-user-group"></em>
            </div>
            <span>Equipe</span>
          </router-link>
        </template>
        Equipe
      </b-tooltip>
    </li>
    <li>
      <b-tooltip x="left">
        <template #activator="{ on }">
          <a role="button" @click="$emit('toggleTheme')" v-on="on">
            <div class="icon">
              <em class="fal" :class="theme === 'light' ? 'fa-moon' : 'fa-sun'"></em>
            </div>
            <span>{{ theme === 'light' ? 'Modo escuro' : 'Modo claro' }}</span>
          </a>
        </template>
        {{ theme === 'light' ? 'Modo escuro' : 'Modo claro' }}
      </b-tooltip>
    </li>
  </ul>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  emits: ['toggleTheme', 'openNotifications'],
  computed: {
    ...mapGetters({
      blobPermission: 'session/blobPermission',
      user: 'auth/loggedUser',
      theme: 'theme/theme'
    }),
    userInitials () {
      return this.user?.name
        ? this.user.name.split(' ').map(word => word.charAt(0)).join('')
        : ''
    }
  }
}
</script>
