import { openWindow } from '@/helpers'

export default {
  namespaced: true,
  state: {
    options: [],
    leadsData: [],
    lead: {}
  },
  getters: {
    options: state => state.options,
    leadsData: state => state.leadsData,
    lead: state => state.lead
  },
  mutations: {
    SET_OPTIONS (state, options) {
      state.options = options
    },
    SET_LEADS_DATA (state, data) {
      state.leadsData = data
    },
    SET_SELECTED_LEAD (state, data) {
      state.lead = data
    }
  },
  actions: {
    loadOptions ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios.get('pipeline/lead/options').then((response) => {
        commit('SET_OPTIONS', response.data)
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao carregar opções de leads',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    getOnlyLeads ({ commit, dispatch }) {
      const { $axios } = this.state

      return $axios.get('/pipeline/step/2').then((response) => {
        commit('SET_LEADS_DATA', response.data)
      }).catch(error => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha ao carregar listagem de leads',
          secondsToClose: 5,
          type: 'warn'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    saveLead ({ dispatch }, data) {
      const { $axios } = this.state

      if (!data.companyId || !data.prospectStatusId || !data.originId) return

      const action = data.id ? 'put' : 'post'

      const position = {
        contactsId: data.contacts
          ? data.contacts.map((contact) => contact.id)
          : [],
        responsibleContactId: data.responsible ? data.responsible.id : null,
        companyId: data.companyId,
        chanceToBecomeDeal: data.chanceToBecomeDeal || 0,
        prospectStatusId: data.prospectStatusId,
        leadStatusId: data.leadStatusId || null,
        originId: data.originId,
        note: data.note || null,
        internalFupPriorityId: data.internalFupPriorityId || null,
        customerFupPriorityId: data.customerFupPriorityId || null
      }

      return $axios[action](`/pipeline/lead/${data.id || ''}`, position)
        .then(({ data }) => data)
        .catch((error) => {
          if (!error.parsedErrorText) console.error(error)

          dispatch(
            'snackbar/showSnackbar',
            {
              message: error.parsedErrorText || 'Falha na inserção',
              secondsToClose: 5,
              type: 'error'
            },
            { root: true }
          )

          return Promise.reject(error)
        })
    },
    createLead ({ dispatch }, { note, code, companyId, priorityId, originId, prospectStatusId, leadStatusId = 1, percentage, id }) {
      const body = {
        note,
        companyId,
        priorityId,
        originId,
        prospectStatusId,
        leadStatusId,
        code,
        percentage: percentage
      }

      const { $axios } = this.state

      return $axios.put(`/pipeline/lead/${id}`, body).then(({ data }) => data).catch((error) => {
        if (!error.parsedErrorText) console.error(error)

        dispatch('snackbar/showSnackbar', {
          message: error.parsedErrorText || 'Falha na inserção',
          secondsToClose: 5,
          type: 'error'
        }, { root: true })

        return Promise.reject(error)
      })
    },
    getLeadById ({ commit, dispatch }, data) {
      console.log("getLeadById:", data);
      const { $axios } = this.state

      if (typeof data.leadId === 'undefined') return
      const uri = data.stepId === 5 ? `/pipeline/placed/${data.leadId}` : `/pipeline/${data.leadId}`

      return $axios.get(uri).then((response) => {
        commit('SET_SELECTED_LEAD', response.data)
      }).catch(error => {
        if (!error.response?.data?.authUrl) {
          console.error(error)

          const message = 'Falha ao carregar lead'

          dispatch(
            'snackbar/showSnackbar',
            {
              message,
              type: 'warn'
            },
            { root: true }
          )

          return Promise.reject(error)
        }

        const { authUrl } = error.response.data

        openWindow(authUrl)

        dispatch(
          'snackbar/showSnackbar',
          {
            message: 'É necessário autenticar-se',
            secondsToClose: 5,
            type: 'warn'
          },
          { root: true }
        )

        return Promise.reject(error)


      })
    }
  }
}
